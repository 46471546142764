/* eslint-disable security/detect-non-literal-fs-filename */
import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from './PinkLinkArrow.module.scss';

export default function PinkLinkArrow(props) {
  const { className, children = 'iPhoneの購入もAppleで', href } = props;

  return (
    <OutboundLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cx(styles.link, className)}
    >
      {children}
      <img
        src={require('src/images/pink-right-arrow.svg')}
        alt="pink-right-arrow"
        height={11}
        width={8}
        className={styles.icon}
      />
    </OutboundLink>
  );
}
