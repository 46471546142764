import React from 'react';
import cx from 'classnames';
import Layout from 'src/components/Layout/Layout';
import PinkLinkArrow from 'src/components/PinkLinkArrow';
import LinearCTAButton from 'src/components/LinearCTAButton';
import ImgBanner from 'src/images/landing/furusatochoice/banner.png';
import ImgBenefit1 from 'src/images/landing/furusatochoice/benefit-1.png';
import ImgBenefit21 from 'src/images/landing/furusatochoice/benefit-2-1.png';
import ImgBenefit22 from 'src/images/landing/furusatochoice/benefit-2-2.png';
import ImgBenefit3 from 'src/images/landing/furusatochoice/benefit-3.png';
import ImgBenefit4 from 'src/images/landing/furusatochoice/benefit-4.png';
import ImgCategory1 from 'src/images/landing/furusatochoice/category-1.png';
import ImgCategory2 from 'src/images/landing/furusatochoice/category-2.png';
import ImgCategory3 from 'src/images/landing/furusatochoice/category-3.png';
import ImgCategory4 from 'src/images/landing/furusatochoice/category-4.png';
import ImgCoop from 'src/images/landing/furusatochoice/coop.png';
import Img3Pay from 'src/images/landing/furusatochoice/3pay.png';
import { SITE_METADATA } from 'src/constants';
import styles from 'src/styles/pages/furusatochoice.module.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Hidden } from '@material-ui/core';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/Newlife/SectionCampaignFooter';

const SEOProps = {
  title: SITE_METADATA.plus3Pay.title,
  description: SITE_METADATA.plus3Pay.description,
};

const CATEGORIES = [
  {
    img: ImgCategory1,
    name: '肉',
    link:
      'https://www.furusato-tax.jp/search/2?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent',
  },
  {
    img: ImgCategory2,
    name: '果物',
    link:
      'https://www.furusato-tax.jp/search/7?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent',
  },
  {
    img: ImgCategory3,
    name: 'お米',
    link:
      'https://www.furusato-tax.jp/search/36?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent',
  },
  {
    img: ImgCategory4,
    name: '魚貝類',
    link:
      'https://www.furusato-tax.jp/search/3?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent',
  },
];

export default function Furusatochoice() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <section className={styles.top}>
        <div className={styles.container}>
          <img
            alt="top-banner"
            src={ImgBanner}
            width={520}
            height={343}
            className={styles.banner}
          />
          <div className={styles.right}>
            <img alt="coop" src={ImgCoop} width={260} height={29} />
            <h1 className={styles.title}>
              黒毛和牛や地域自慢のフルーツが たった実質2,000円で手に入る！？
              ふるさと納税もペイディで
            </h1>
            <p className={styles.text}>
              地域の名産品などのお礼の品が実質2,000円で手に入る、ふるさと納税。ふるさとチョイスならペイディが使えるので、あと払いや分割払いで、かしこく計画的にふるさと納税を楽しめます。
            </p>
            <LinearCTAButton
              isExternal
              className={styles.btn}
              text="お礼の品を探す"
              to="https://www.furusato-tax.jp/?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent"
            />
          </div>
        </div>
      </section>

      <section className={styles.category}>
        <h2 className={styles.title}>お礼の品　人気カテゴリー</h2>
        <div className={styles.container}>
          {CATEGORIES.map((category) => (
            <div key={category.name}>
              <OutboundLink
                href={category.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={category.name}
                  src={category.img}
                  width={152}
                  height={152}
                />
              </OutboundLink>
              <p className={styles.text}>{category.name}</p>
            </div>
          ))}
        </div>
      </section>

      <section className={styles.benefits}>
        <h2 className={styles.title1}>＼はじめないと損をする！／</h2>
        <h2 className={styles.title2}>
          ふるさと納税の
          <Hidden smUp>
            <br />
          </Hidden>
          お得なメリット
        </h2>
        <div>
          <div className={styles.container}>
            <div className={styles.item}>
              <img alt="benefit-1" src={ImgBenefit1} width={184} height={184} />
              <h4 className={styles.title}>実質2,000円でお礼の品がもらえる</h4>
              <p className={styles.text}>
                手続きをすると、2,000円を超える部分については、所得税の還付、住民税の控除が受けられます。
              </p>
            </div>
            <div className={styles.item2}>
              <div className={styles.row}>
                <div>
                  <h4 className={styles.title}>例1</h4>
                  <img
                    alt="benefit-2-1"
                    src={ImgBenefit21}
                    width={88}
                    height={88}
                    className={styles.img}
                  />
                </div>
                <p className={styles.text}>
                  年収400万円の独身者または共働きの方であれば控除上限額43,000円が目安となります。
                </p>
              </div>
              <div className={styles.row}>
                <div>
                  <h4 className={styles.title}>例2</h4>
                  <img
                    alt="benefit-2-2"
                    src={ImgBenefit22}
                    width={88}
                    height={88}
                    className={styles.img}
                  />
                </div>
                <p className={styles.text}>
                  ご夫婦で暮らす年収600万円の方は68,000円、妻と高校生のお子さん1人を扶養している
                  年収700万円の方であれば77,000円が目安です。
                </p>
              </div>
            </div>
          </div>
          <PinkLinkArrow
            href="https://www.furusato-tax.jp/about/simulation?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent"
            className={styles.pinkLink1}
          >
            控除上限額チェック
          </PinkLinkArrow>
          <div className={styles.container}>
            <div className={styles.item}>
              <img alt="benefit-3" src={ImgBenefit3} width={184} height={184} />
              <h4 className={styles.title}>日本各地の名産品から自由に選べる</h4>
              <p className={cx(styles.text, styles.light)}>
                地域自慢の和牛やフルーツ、お米などの名産品から、日用品や旅行券、家具まで、多彩なお礼の品から好きなものを自由に選べます。
              </p>
              <PinkLinkArrow
                href="https://www.furusato-tax.jp/?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent"
                className={styles.pinkLink2}
              >
                お礼の品を探す
              </PinkLinkArrow>
            </div>
            <div className={styles.item}>
              <img alt="benefit-4" src={ImgBenefit4} width={184} height={184} />
              <h4 className={styles.title}>手続きはとっても簡単</h4>
              <p className={styles.text}>
                1年間で5自治体までなら、「ワンストップ特例制度」で確定申告が不要。手続きも簡単です。
              </p>
              <PinkLinkArrow
                className={styles.pinkLink2}
                href="https://www.furusato-tax.jp/about/onestop?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent"
              >
                手続きの詳細を見る
              </PinkLinkArrow>
            </div>
          </div>
        </div>
      </section>

      {/* <section className={styles.payLater}>
        <h3 className={styles.title1}>
          さらに今なら<b>必ずもらえる</b>
        </h3>
        <h3 className={styles.title2}>
          「 あと払い（ペイディ）」を初めて <br />
          利用して10,000円以上寄付すると
        </h3>
        <h3 className={styles.title3}>2,000円キャッシュバック！</h3>
        <LinearCTAButton
          isExternal
          className={styles.btn}
          text="詳しくはこちら"
          to="https://paidy.com/campaign/furusatochoice_2022_october"
        />
      </section> */}

      <section className={styles.canDo}>
        <h2 className={styles.title}>
          ペイディならかしこく
          <Hidden smUp>
            <br />
          </Hidden>
          計画的に楽しめる！
        </h2>
        <div className={styles.container}>
          <img
            alt="plan"
            src={Img3Pay}
            width={375}
            height={286}
            className={styles.img}
          />
          <div className={styles.right}>
            <p className={styles.text}>
              寄付金額が高額なふるさと納税も、ペイディならお支払いは翌月なので、計画的に楽しめます。
              <br />
              <br />
              さらに分割手数料無料*の３回あと払いを利用すれば、余計な出費なしでお支払いを３回に分けることも可能。毎月のお支払いを少額に調整することができます。
            </p>
            <small className={styles.small}>
              *口座振替・銀行振込のみ無料。コンビニ払いの場合、月一回支払い時に最大税込356円（すぐ払い利用時のみ支払いごと）に手数料が発生します。
            </small>
            <LinearCTAButton
              isExternal
              className={styles.btn}
              text="お礼の品を探す"
              to="https://www.furusato-tax.jp/?utm_source=paidy&utm_medium=alliance&utm_content=web&utm_campaign=Educationalcontent"
            />
          </div>
        </div>
      </section>

      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
